@import '../../scss/functions';
@import '../Typography/scss/mixins';

.container {
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
}

.prefix {
  position: absolute;
  top: 4px;
}

.disabled .prefix {
  opacity: 0.7;
}

.input {
  @include text(2, 'semibold');

  width: 100%;
  margin: 0;
  padding: 4px 0 8px;
  font-family: inherit;
  background: no-repeat bottom;
  background-color: transparent;
  background-image: linear-gradient(
      0deg,
      var(--color-success) 1px,
      rgba(0, 150, 136, 0) 0
    ),
    linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
  background-size: 0 100%, 100% 100%;
  border: none;
  border-radius: 0;
  outline: none;
  transition: background 0s ease-out;
}

.input[type='number'] {
  -moz-appearance: textfield;
}

.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.input::-ms-clear,
.input::-ms-reveal {
  display: none;
}

.input:focus {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
}

.input::placeholder {
  color: get-color('text', 'secondary');
}

.input:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.hasError {
  background-image: linear-gradient(
      0deg,
      var(--color-warning) 1px,
      rgba(0, 150, 136, 0) 0
    ),
    linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
  background-size: 100% 100%, 100% 100%;
}

.input[type='search']::-webkit-search-cancel-button {
  width: 11px;
  height: 11px;
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgdmlld0JveD0iMCAwIDExIDExIj4KICA8ZGVmcy8+CiAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMWExNzE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik04Ljc1IDEuOTdMMS42NCA5bTcuMDcuMDRMMS42OCAxLjkzIi8+Cjwvc3ZnPgo=')
    no-repeat;
  background-size: contain;
  appearance: none;
}

.input[type='search']::-ms-clear {
  width: 11px;
  height: 11px;
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgdmlld0JveD0iMCAwIDExIDExIj4KICA8ZGVmcy8+CiAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMWExNzE3IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik04Ljc1IDEuOTdMMS42NCA5bTcuMDcuMDRMMS42OCAxLjkzIi8+Cjwvc3ZnPgo=')
    no-repeat;
  background-size: contain;
  appearance: none;
}
