.formErrors {
  max-width: 355px;
  margin: 15px 0 0 0;
  padding: 0;
  color: var(--color-warning);
  list-style: none;
}

.formErrors > li:not(:first-child) {
  margin-top: 5px;
}
