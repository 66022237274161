@import '../../scss/functions';

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
}

.avatarImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  border-radius: inherit;
}

.avatarInitials {
  color: #fff;
  font-weight: map-get($weights, 'extraBold');
  line-height: 0;
  letter-spacing: 0;
}
