@import '../../scss/common';
@import '../../scss/title';

.title {
  margin: 0;
  font-weight: map-get($weights, 'black');
}

.titleLevel1 {
  @extend %titleLevel1;
}

.titleLevel2 {
  @extend %titleLevel2;
}

.titleLevel3 {
  @extend %titleLevel3;
}

.titleLevel4 {
  @extend %titleLevel4;
}

.titleLevel5 {
  @extend %titleLevel5;
}

.titleLevel6 {
  @extend %titleLevel6;
}
