%textLevel1 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
}

%textLevel2 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.1px;
}

%textLevel3 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

%textLevel4 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
}

%textLevel5 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
}
