@import '../../scss/functions';
@import '../Typography/scss/mixins';

%common {
  border-radius: 8px;
}

%default-padding {
  padding: 12px 20px;
}

%small-padding {
  padding: 8px 16px;
}

.note {
  @include text(3);

  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  align-items: baseline;
}

.icon {
  background-color: get-color('brand', 'primaryYellow');
  border-radius: 4px;
}

.important {
  @extend %common;
  @extend %default-padding;

  background-color: rgba(get-color('brand', 'primaryYellow'), 0.2);
}

.info {
  @extend %common;
  @extend %default-padding;

  background-color: get-color('background', 'secondary');
}

.importantSmall {
  @extend .important;
  @extend %small-padding;

  @include text(5, 'semibold');
}

.error {
  @extend %common;
  @extend %small-padding;

  @include text(4);

  color: get-color('text', 'warning');
  background-color: rgba(get-color('system', 'warning'), 0.1);
}
