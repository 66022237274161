@import '~@appclose/ui/src/scss/mixins';

.modal {
  width: 100%;
  max-width: 460px;
  padding: 32px 32px 48px 32px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.08);

  .main {
    word-break: break-word;
  }

  .header {
    margin-bottom: 36px;
  }

  .title {
    margin-left: 12px;
  }

  &.page {
    max-width: 720px;
    margin: 0 auto;
    padding: 80px 0 120px;
    box-shadow: none;
  }
}

.pageOverlay {
  align-items: flex-start;
  padding: 0;
  background-color: white;
}

@media print {
  .modal.page .header {
    display: none;
  }
}

@include mobile {
  .overlay:not(.pageOverlay) {
    padding: 10px 20px;
  }

  .modal {
    margin: auto;
    padding: 20px 20px 32px 20px;
  }

  .modal.page {
    margin: 0;
    padding: 20px 20px 58px;
  }
}
