.datePicker {
  position: relative;
  width: 100%;
}

.datePickerInput {
  padding: 4px 20px 8px 0;
}

.withClear {
  padding-right: 40px;
}

.datePickerInput:disabled {
  cursor: not-allowed;
}

.datePickerInputContainer {
  pointer-events: none;
}

.arrowIcon {
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
  display: block;
  width: 24px;
  height: 24px;
  background: transparent
    url('~@appclose/icons/src/assets/arrow-down-light.svg') center no-repeat;
  opacity: 0.3;
  transition: transform ease 0.5s;
  pointer-events: none;
}

.arrowIcon.isOpen {
  transform: scaleY(-1);
}

.clearIcon {
  position: absolute;
  top: calc(50% - 18px);
  right: 20px;
  display: block;
  width: 24px;
  height: 24px;
  background: transparent url('~@appclose/icons/src/assets/clear-light.svg')
    center no-repeat;
  cursor: pointer;
  opacity: 0.3;
}

.clearIcon:hover {
  opacity: 1;
}

:global(.flatpickr-calendar .flatpickr-monthDropdown-months) {
  margin-right: 10px;
  border-bottom: solid #e8ecec 1px;
  appearance: auto !important;
}

:global(.flatpickr-calendar .numInputWrapper) {
  border-bottom: solid #e8ecec 1px;
}

:global(.flatpickr-calendar .flatpickr-monthDropdown-months:hover),
:global(.flatpickr-calendar .numInputWrapper:hover) {
  background-color: transparent;
  border-bottom-color: var(--color-success);
}

:global(.flatpickr-calendar .numInputWrapper .arrowUp),
:global(.flatpickr-calendar .numInputWrapper .arrowDown) {
  border: none;
  opacity: 1;
}

:global(.flatpickr-calendar .numInputWrapper span:hover),
:global(.flatpickr-calendar .numInputWrapper span:focus) {
  background: none;
}

:global(.flatpickr-calendar .numInputWrapper span.arrowDown::after) {
  top: 20%;
}

:global(.flatpickr-calendar .numInputWrapper span.arrowUp::after) {
  top: 40%;
}

:global(.flatpickr-calendar) {
  width: auto;
  padding: 25px 40px 30px;
  color: var(--color-default);
  border-radius: 22px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
}

:global(.flatpickr-calendar::before),
:global(.flatpickr-calendar::after) {
  content: none;
}

:global(.flatpickr-months) {
  position: relative;
  align-items: center;
}

:global(.flatpickr-months .flatpickr-next-month),
:global(.flatpickr-months .flatpickr-prev-month) {
  top: 8px;
  width: 24px;
  height: 24px;
  padding: 0;
}

:global(.flatpickr-months .flatpickr-next-month) {
  background-image: url('~@appclose/icons/src/assets/arrow-medium.svg');
}

:global(.flatpickr-months .flatpickr-prev-month) {
  background-image: url('~@appclose/icons/src/assets/arrow-medium.svg');
  transform: scaleX(-1);
}

:global(.flatpickr-current-month .flatpickr-monthDropdown-months) {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.32px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

:global(.flatpickr-current-month input.cur-year) {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.32px;
}

:global(.flatpickr-innerContainer) {
  margin-top: 30px;
}

:global(.flatpickr-weekdays) {
  height: auto;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  opacity: 0.5;
}

:global(.flatpickr-weekdays .flatpickr-weekday) {
  font-weight: normal;
}

:global(.flatpickr-days) {
  width: 290px;
}

:global(.dayContainer) {
  width: 290px;
  min-width: 290px;
  max-width: 290px;
}

:global(.flatpickr-day) {
  max-width: 37px;
  height: 37px;
  font-weight: 500;
  font-size: 12px;
  line-height: 35px;
  letter-spacing: 1.2px;
}

:global(.flatpickr-day.selected),
:global(.flatpickr-day.startRange),
:global(.flatpickr-day.endRange),
:global(.flatpickr-day.selected.inRange),
:global(.flatpickr-day.startRange.inRange),
:global(.flatpickr-day.endRange.inRange),
:global(.flatpickr-day.selected:focus),
:global(.flatpickr-day.startRange:focus),
:global(.flatpickr-day.endRange:focus),
:global(.flatpickr-day.selected:hover),
:global(.flatpickr-day.startRange:hover),
:global(.flatpickr-day.endRange:hover),
:global(.flatpickr-day.selected.prevMonthDay),
:global(.flatpickr-day.startRange.prevMonthDay),
:global(.flatpickr-day.endRange.prevMonthDay),
:global(.flatpickr-day.selected.nextMonthDay),
:global(.flatpickr-day.startRange.nextMonthDay),
:global(.flatpickr-day.endRange.nextMonthDay),
:global(.flatpickr-day:hover),
:global(.flatpickr-day.prevMonthDay:hover),
:global(.flatpickr-day.nextMonthDay:hover),
:global(.flatpickr-day:focus),
:global(.flatpickr-day.prevMonthDay:focus),
:global(.flatpickr-day.nextMonthDay:focus) {
  color: #fff;
  background: var(--color-primary);
  border-color: var(--color-primary);
}

:global(.flatpickr-day.today) {
  border-color: #56ceff;
}

:global(.flatpickr-day.today:focus),
:global(.flatpickr-day.today:hover) {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

:global(.flatpickr-day.flatpickr-disabled),
:global(.flatpickr-day.flatpickr-disabled:hover),
:global(.flatpickr-day.prevMonthDay),
:global(.flatpickr-day.nextMonthDay),
:global(.flatpickr-day.notAllowed),
:global(.flatpickr-day.notAllowed.prevMonthDay),
:global(.flatpickr-day.notAllowed.nextMonthDay) {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

:global(.flatpickr-day.inRange),
:global(.flatpickr-day.prevMonthDay.inRange),
:global(.flatpickr-day.nextMonthDay.inRange),
:global(.flatpickr-day.today.inRange),
:global(.flatpickr-day.prevMonthDay.today.inRange),
:global(.flatpickr-day.nextMonthDay.today.inRange) {
  background: #56ceff;
  border-color: #56ceff;
}

:global(.flatpickr-day.inRange) {
  z-index: -1;
  color: #fff;
  box-shadow: -5px 0 0 #56ceff, 5px 0 0 #56ceff;
}

:global(.flatpickr-day.inRange:nth-child(7n)) {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: none;
}

:global(.flatpickr-day.inRange:nth-child(7n + 1)) {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: none;
}

:global(.flatpickr-day.startRange
    + .flatpickr-day.inRange:not(:nth-child(7n + 1))::after) {
  position: absolute;
  top: -1px;
  right: 100%;
  bottom: -1px;
  left: calc(-2px + -50%);
  z-index: -1;
  background-color: #56ceff;
  content: '';
}

:global(.flatpickr-day.inRange
    + .flatpickr-day.endRange:not(:nth-child(7n + 1))::after) {
  position: absolute;
  top: -1px;
  right: 50%;
  bottom: -1px;
  left: -50%;
  z-index: -1;
  background-color: #56ceff;
  content: '';
}

:global(.flatpickr-day.selected.startRange),
:global(.flatpickr-day.startRange.startRange),
:global(.flatpickr-day.endRange.startRange) {
  border-radius: 50px;
}

:global(.flatpickr-day.selected.endRange),
:global(.flatpickr-day.startRange.endRange),
:global(.flatpickr-day.endRange.endRange) {
  border-radius: 50px;
}

:global(.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1))),
:global(.flatpickr-day.startRange.startRange
    + .endRange:not(:nth-child(7n + 1))),
:global(.flatpickr-day.endRange.startRange
    + .endRange:not(:nth-child(7n + 1))) {
  box-shadow: none;
}

:global(.flatpickr-day.selected.startRange
    + .endRange:not(:nth-child(7n + 1))::after),
:global(.flatpickr-day.startRange.startRange
    + .endRange:not(:nth-child(7n + 1))::after),
:global(.flatpickr-day.endRange.startRange
    + .endRange:not(:nth-child(7n + 1))::after) {
  position: absolute;
  top: -1px;
  right: 50%;
  bottom: -1px;
  left: calc(-2px + -50%);
  z-index: -1;
  background-color: #56ceff;
  content: '';
}
