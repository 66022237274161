@import '../../scss/functions';

.sort {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin-left: 4px;
  transform: scaleY(-1);
  fill: get-color('icons', 'gray030');
}

.icon > path {
  stroke-width: 2;
}

.iconAsc {
  transform: scaleY(1);
}

.active {
  color: get-color('text', 'primary');
}

.active .icon {
  fill: get-color('icons', 'primary');
}
