@import '../../scss/functions';
@import '../Typography/scss/mixins';

$buttonPadding: 16px;
$arrowIconSize: 20px;

.quantity {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 134px;
  height: 44px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.05);
}

.button {
  padding: 0;
  font: inherit;
  line-height: 1;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;

  &:first-of-type {
    padding-left: $buttonPadding;
  }

  &:last-of-type {
    padding-right: $buttonPadding;
  }

  &:hover {
    opacity: 0.8;
  }

  path {
    transition: fill 0.3s ease;
  }

  &:not(:hover) path {
    fill: get-color('icons', 'secondary');
  }

  &:disabled {
    pointer-events: none;

    & path {
      fill: get-color('icons', 'gray010');
    }
  }
}

.quantity:not(.field) {
  .input {
    @include caption(1, 'bold');

    width: 40.298507462686567%;
    padding: 0;
    font-family: inherit;
    text-align: center;
    background: none;
    border: none;
    appearance: none;
  }
}

.input {
  outline: none;

  /**
   * Hide arrows from input number
   * https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
   */

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.disabled {
  pointer-events: none;

  .input {
    opacity: 0.5;
  }
}

.field {
  position: relative;
  display: block;
  width: auto;
  max-width: 80px;
  height: auto;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;

  .button {
    position: absolute;
    right: 0;
    z-index: 1;
    height: $arrowIconSize;
    padding: 0;

    &:first-of-type {
      bottom: 3px;
    }

    &:last-of-type {
      top: -3px;
    }

    svg {
      width: $arrowIconSize;
      height: $arrowIconSize;
    }
  }
}
