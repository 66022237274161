@import '../../../scss/variables';
@import 'variables';

@mixin generate-spacing-css-variables() {
  @each $breakpoint, $sizes in $spacings-by-breakpoints {
    $min-width: map-get($breakpoints, $breakpoint);

    @media (min-width: $min-width) {
      :root {
        @each $size, $spacing in $sizes {
          --spacing-#{$size}: #{$spacing};
        }
      }
    }
  }
}
