.info {
  color: var(--color-info);
}

.warning {
  color: var(--color-warning);
}

.success {
  color: var(--color-success);
}

.strong {
  font-weight: 900;
}
