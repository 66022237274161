.container {
  position: relative;
  height: 100%;
  min-height: var(--loader-height);
}

.loader {
  top: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fixed {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.sticky {
  position: sticky;
  top: 50vh;
  bottom: 50vh;
  padding: calc(var(--loader-height) / 2);
}
