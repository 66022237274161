@import '~@appclose/ui/src/scss/mixins';

@value controlSize: 20px;

.checkbox {
  @include accessibilityHidden();
}

.label {
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.label:empty {
  line-height: 0;
}

.label::before,
.label::after {
  display: block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  content: '';
}

.label::before {
  border: 1px solid #e2e7e7;
  border-radius: 6px;
}

.label::after {
  position: absolute;
  left: 0;
}

.hasLabel.label::before {
  margin-right: 15px;
}

.checkbox:checked ~ .label::before {
  background: var(--color-success);
  border-color: var(--color-success);
}

.checkbox:checked ~ .label::after {
  background: url('~@appclose/icons/src/assets/checkBold.svg') no-repeat center
    center;
  background-size: 16px 16px;
  filter: invert(100%) brightness(150%) contrast(100%);
}

.light .label {
  color: #868b8e;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
}

.light .label a {
  color: #868b8e;
}

.readOnly {
  cursor: unset;
  pointer-events: none;
}

.disabled {
  @extend .readOnly;

  opacity: 0.4;
}
