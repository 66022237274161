@import '../../../Typography/scss/mixins';
@import '../../../../scss/mixins';

.cell {
  @include text(4, 'semibold');

  height: 60px;
  padding: 18px 15px;
  text-align: left;
  vertical-align: middle;
}

.headCell {
  color: #99a0a3;
  font-size: 13px;
  line-height: 18px;
}

.cell:last-child {
  padding-right: 20px;
}

th.cell {
  height: 30px;
  padding: 5px 15px;
  font-weight: normal;
}

.light {
  color: var(--color-info);
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
}

.strong {
  font-weight: 900;
}

.icon {
  width: 50px;
  line-height: 0;
}

.context {
  width: 70px;
  text-align: right;
}

.noWordBreak {
  word-break: keep-all;
}

@include mobile {
  .cell {
    padding: 18px 8px;
  }

  th.cell {
    padding: 5px 8px;
  }

  .icon {
    width: 32px;
  }
}
