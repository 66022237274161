$sides: 'top', 'right', 'bottom', 'left';

$breakpoints: (
  xs: 0,
  xl: 1025px,
);

$size: 4px;

$colors: (
  'icons': (
    'primary': #1b1d1e,
    'secondary': #989fa3,
    'contrast': #fff,
    'gray030': #babbbb,
    'gray010': #d1d2d2,
  ),
  'text': (
    'primary': #1b1d1e,
    'secondary': #989fa3,
    'contrast': #fff,
    'hover': #484a4b,
    'gray': #989fa3,
    'gray030': #babbbb,
    'warning': #ff5353,
    'links': #20c2d2,
  ),
  'background': (
    'primary': #fff,
    'secondary': #f8f9fa,
    'tertiary': #ebeeee,
    'black': #1b1d1e,
  ),
  'system': (
    'primary': #20c2d2,
    'success': #a4e750,
    'warning': #ff5353,
    'gray': #989fa3,
    'primary-hover': #25ccdd,
    'success-hover': #abed57,
    'gray-hover': #a3acb1,
    'primary-active': #02bed0,
    'success-active': #9ad949,
    'gray-active': #969ea3,
  ),
  'brand': (
    'primaryYellow': #fddc03,
    'primaryYellow-hover': #ffe328,
    'primaryYellow-active': #fdd503,
  ),
  'avatars': (
    'yellow': #fddc03,
    'green': #a4e750,
    'turquoise': #20c2d2,
    'default': #ff5353,
    'darkBLue': #2775eb,
    'orange': #ffa132,
    'blue': #53c1ff,
    'violet': #7557ed,
    'pink': #e47af5,
    'darkGreen': #33ad69,
    'gray': #989fa3,
  ),
  'calendar': (
    'green': #a4e750,
    'turquoise': #20c2d2,
    'red': #ff5353,
  )
);

$weights: (
  'light': 300,
  'regular': 400,
  'semibold': 600,
  'bold': 700,
  'extraBold': 800,
  'black': 900,
);
