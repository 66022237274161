.field {
  width: 100%;
}

.hidden {
  display: none;
}

.showError {
  margin-bottom: -20px;
}

.description {
  margin: 0;
  font-size: 13px;
  opacity: 0.5;
}

.animatedPlaceholder::placeholder,
.animatedPlaceholder input::placeholder {
  opacity: 0;
  transition: all 0.3s ease;
}

.placeholderShown::placeholder,
.placeholderShown input::placeholder {
  opacity: 1;
}

/** Separate definition for edge fix  https://appclose.atlassian.net/browse/LQUO-870 **/
.animatedPlaceholder div[data-placeholder]::before {
  opacity: 0;
  transition: all 0.3s ease;
}

.placeholderShown div[data-placeholder]::before {
  opacity: 1;
}
