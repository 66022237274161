@value borderRadius from '../../Table.module.scss';

.head {
  white-space: nowrap;
  border: none;
}

.row:last-child {
  border-bottom: none;
}

.th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8f9fa;
}

.row:first-child .th:first-child {
  border-top-left-radius: borderRadius;
}

.row:last-child .th:first-child {
  border-bottom-left-radius: borderRadius;
}

.row:first-child .th:last-child {
  border-top-right-radius: borderRadius;
}

.row:last-child .th:last-child {
  border-bottom-right-radius: borderRadius;
}

.medium {
  .th,
  .row:hover th {
    background-color: #fff;
  }

  .th {
    padding-top: 2px;
    padding-bottom: 10px;
  }

  .th::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #ebeeee;
    content: '';
  }

  .row:first-child .th:first-child {
    border-top-left-radius: 0;
  }

  .row:last-child .th:first-child {
    border-bottom-left-radius: 0;
  }

  .row:first-child .th:last-child {
    border-top-right-radius: 0;
  }

  .row:last-child .th:last-child {
    border-bottom-right-radius: 0;
  }
}
