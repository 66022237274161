@import '../../scss/mixins';
@import './scss/mixins';

@include generate-spacing-css-variables();

@each $breakpoint, $sizes in $spacings-by-breakpoints {
  $min-width: map-get($breakpoints, $breakpoint);

  @media (min-width: $min-width) {
    @include make-offsets($sizes);
  }
}
