.block {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
}

.clickable {
  cursor: pointer;
  transition: box-shadow ease-out 0.5s;

  &:hover {
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.08);
  }
}
