.bodyModalOpen {
  overflow: hidden;
}

.modalOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.2); 
}

.modal {
  position: relative;
  z-index: 2;
  margin: auto;
  outline: none;
}
