%captionLevel1 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
}

%captionLevel2 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
}

%captionLevel3 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
}
