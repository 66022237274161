.field {
  display: flex;
  align-items: center;

  .toggleBox {
    box-sizing: border-box;
    border: 1px solid #ebeeee;
    border-radius: 6px;
    cursor: pointer;
  }
}
