@import '~@appclose/ui/src/scss/mixins';
@import '~@appclose/ui/src/components/Typography/scss/mixins';

.fieldset {
  min-width: 0; /* Overrides browser fieldset min-width value https://stackoverflow.com/a/27660473 */
  margin: 0 0 60px;
  padding: 0;
  border: none;
}

.legend {
  width: 100%;
  padding: 9px 20px 6px;
  background-color: var(--color-utility);
  border-radius: 8px;
}

.legendContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @include caption(3, 'black');

  display: flex;
  align-items: center;
  margin: 0;
  letter-spacing: 1.7px;
  text-transform: uppercase;
}

.content {
  padding: 20px 20px 0;
}

@include mobile {
  .fieldset {
    margin: 0 0 40px;
  }
}
