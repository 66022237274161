@import '../../../scss/variables';
@import '../../../scss/functions';

.primary {
  color: get-color('text', 'primary');
}

.secondary {
  color: get-color('text', 'secondary');
}

.contrast {
  color: get-color('text', 'contrast');
}

.gray {
  color: get-color('text', 'gray');
}

.gray030 {
  color: get-color('text', 'gray030');
}

.warning {
  color: get-color('text', 'warning');
}

.light {
  font-weight: map-get($weights, 'light');
}

.regular {
  font-weight: map-get($weights, 'regular');
}

.semibold {
  font-weight: map-get($weights, 'semibold');
}

.bold {
  font-weight: map-get($weights, 'bold');
}

.extraBold {
  font-weight: map-get($weights, 'extraBold');
}

.black {
  font-weight: map-get($weights, 'black');
}
