@import 'common';
@import 'title';
@import 'text';
@import 'caption';
@import 'number';

@mixin caption($level, $weight: 'regular', $color: 'primary') {
  @extend %captionLevel#{$level};

  color: get-color('text', $color);
  font-weight: map-get($weights, $weight);
}

@mixin number($level, $color: 'primary') {
  @extend %numberLevel#{$level};

  color: get-color('text', $color);
}

@mixin text($level, $weight: 'regular', $color: 'primary') {
  @extend %textLevel#{$level};

  color: get-color('text', $color);
  font-weight: map-get($weights, $weight);
}

@mixin title($level, $color: 'primary') {
  @extend %titleLevel#{$level};

  color: get-color('text', $color);
  font-weight: map-get($weights, 'black');
}
