@import '../../../scss/functions';

$buttons: (
  'primary': (
    'base': (
      color: get-color('text', 'contrast'),
      background-color: get-color('system', 'primary'),
      box-shadow: 0 5px 12px rgba(32, 194, 210, 0.3),
    ),
    'hover': (
      background-color: get-color('system', 'primary-hover'),
    ),
    'active': (
      background-color: get-color('system', 'primary-active'),
      box-shadow: none,
    ),
  ),
  'success': (
    'base': (
      color: get-color('text', 'contrast'),
      background-color: get-color('system', 'success'),
      box-shadow: 0 5px 10px rgba(164, 231, 80, 0.3),
    ),
    'hover': (
      background-color: get-color('system', 'success-hover'),
    ),
    'active': (
      background-color: get-color('system', 'success-active'),
      box-shadow: none,
    ),
  ),
  'secondary': (
    'base': (
      color: get-color('text', 'primary'),
      background-color: get-color('background', 'primary'),
      box-shadow: 0 5px 12px rgba(152, 159, 163, 0.3),
    ),
    'hover': (
      color: get-color('text', 'hover'),
    ),
    'active': (
      color: get-color('text', 'primary'),
      background-color: get-color('background', 'secondary'),
      box-shadow: none,
    ),
  ),
  'brand': (
    'base': (
      color: get-color('text', 'primary'),
      background-color: get-color('brand', 'primaryYellow'),
      box-shadow: 0 5px 12px rgba(253, 252, 3, 0.3),
    ),
    'hover': (
      background-color: get-color('brand', 'primaryYellow-hover'),
    ),
    'active': (
      background-color: get-color('brand', 'primaryYellow-active'),
      box-shadow: none,
    ),
  ),
  'info': (
    'base': (
      color: get-color('text', 'contrast'),
      background-color: get-color('system', 'gray'),
      box-shadow: 0 5px 12px rgba(152, 159, 163, 0.3),
    ),
    'hover': (
      background-color: get-color('system', 'gray-hover'),
    ),
    'active': (
      background-color: get-color('system', 'gray-active'),
      box-shadow: none,
    ),
  ),
  'ghost': (
    'base': (
      display: inline-block,
      min-height: auto,
      padding: 0,
      color: get-color('text', 'primary'),
      font-weight: normal,
      line-height: 0,
      text-align: left,
      background: none,
      border-radius: 0,
    ),
    'hover': (
      opacity: 0.8,
    ),
    'active': (
      opacity: 1,
    ),
  ),
);
