@import '../../../../../scss/variables';
@import '../../../scss/variables';

@mixin make-offset($size, $side: null) {
  $infix: if($side == null, '', '-#{$side}');

  :global(.offset#{$infix}-#{$size}) {
    margin#{$infix}: var(--spacing-#{$size});
  }
}

@mixin make-offsets($sizes) {
  @each $size, $spacing in $sizes {
    @each $side in $sides {
      @include make-offset($size, $side);
    }

    @include make-offset($size);
  }
}
