@import '~@appclose/ui/src/scss/mixins';

:global(.react-notification-root) {
  top: 0;
}

:global(.react-notification-root) > div {
  width: auto;
  pointer-events: none;
}

:global(.notification-item) {
  padding: 10px 40px 10px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: none;
  border-radius: 10px;
  box-shadow: none;
}

:global(.notification-item .notification-message) {
  display: inline;
  max-width: 100%;
  line-height: normal;
}

:global(.notification-item .notification-content) {
  padding: 0;
}

:global(.notification-item .notification-close) {
  top: 0;
  right: 20px;
  bottom: 0;
  width: auto;
  height: auto;
  margin: auto 0;
  line-height: 0;
  pointer-events: all;
}

:global(.notification-item .notification-title) {
  display: inline;
  margin: 0 4px 0 0;
  font-weight: inherit;
}

:global(.notification-item.notification-success) {
  background-color: var(--color-success);
}

:global(.notification-item.notification-info) {
  background-color: var(--color-primary);
}

:global(.notification-item.notification-danger) {
  background-color: var(--color-warning);
}

:global(.react-notification-root .notification-container-top-center) {
  left: unset;
  width: 100%;
}

:global(.react-notification-root .notification-container-center) {
  height: auto;
}

:global(.notification-item .notification-close::after) {
  font-size: 20px;
}

:global(.react-notification-root
    .notification-container-top-center
    .notification-parent) {
  width: auto !important;
}

@include mobile {
  :global(.n-parent) {
    text-align: center;
  }

  :global(.react-notification-root) > div {
    pointer-events: all;
  }
}
