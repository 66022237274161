@import '~@appclose/ui/src/scss/mixins';

.formArrayField:not(:last-child) {
  margin-bottom: 40px;
}

.addButton {
  margin-top: 10px;
}

.error {
  margin: 15px 0 0;
  text-align: unset;
}

@include mobile {
  .formArrayField {
    margin-bottom: 40px;
  }
}
