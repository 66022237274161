@import '~@appclose/ui/src/scss/mixins';

.radioInput {
  @include accessibilityHidden();
}

.radioLabel {
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
}

.radioIcon {
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  line-height: 0;
  border: 1px solid #e2e7e7;
  border-radius: 10px;
}

.radioIcon:not(:only-child) {
  margin-right: 20px;
}

.radioInput:not(:checked) ~ .radioLabel > div {
  opacity: 0.6;
}

.radioInput:checked ~ .radioLabel .radioIcon {
  background-color: var(--color-success);
  border: none;
}

.radioLabel .radioIcon::before {
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-radius: 10px;
  content: '';
}

.radioInput:checked ~ .radioLabel .radioIcon::before {
  border: 1px solid #fff;
}

.disabled {
  cursor: unset;
  opacity: 0.4;
  pointer-events: none;
}
