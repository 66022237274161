.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.alignCenter {
  justify-content: center;
}

.button {
  flex: 0 0 auto;
}
