@import '~@appclose/ui/src/scss/functions';

.dropZone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: 12px;
  border: 1px dashed get-color('background', 'tertiary');
  border-radius: 10px;
}

.dropZoneOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(14, 185, 255, 0.15);
  pointer-events: none;
}

.dropZoneHover .dropZoneOverlay {
  display: block;
}

.dragAndDropLabel {
  opacity: 0.5;
  pointer-events: initial;
}

.attachIcon {
  margin-right: 4px;
  vertical-align: bottom;
}

.dropZoneContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.dropZoneHover .dragAndDropLabel {
  pointer-events: none;
}

.fileInputLabel {
  text-decoration: underline;
  cursor: pointer;
}

.fileInput {
  display: none;
}
