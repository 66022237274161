@import '../../scss/common';
@import '../../scss/caption';

.caption {
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}
.captionLevel1 {
  @extend %captionLevel1;
}

.captionLevel2 {
  @extend %captionLevel2;
}

.captionLevel3 {
  @extend %captionLevel3;
}
