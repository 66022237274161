@import '../../scss/variables';
@import '../../scss/functions';

$counter-size: 24px;

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ordered {
  counter-reset: ordered-list;
}

.ordered > li {
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: $counter-size 1fr;
}

.ordered > li::before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $counter-size;
  height: $counter-size;
  color: get-color('text', 'contrast');
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  background-color: get-color('system', 'success');
  border-radius: 100%;
  content: counters(ordered-list, '.');
  counter-increment: ordered-list;
}
