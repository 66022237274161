@import '~@appclose/ui/src/components/Typography/scss/mixins';

.staticField {
  position: relative;

  &.hasTitle {
    padding-top: 24px;
  }
}

.title {
  @include caption(3, 'regular', 'secondary');

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  pointer-events: none;
}

.content {
  @include text(2, 'semibold');

  overflow-wrap: break-word;
}

.compact {
  .content {
    @include text(3, 'semibold');
  }
}

.disabled .content {
  opacity: 0.4;
}

.clear {
  font-weight: inherit;
  font-size: inherit;
}

.hint {
  margin-left: 10px;
  pointer-events: auto;
}
