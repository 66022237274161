@import '../../scss/functions';
@import '../Typography/scss/mixins';

.textArea {
  @include text(2, 'semibold');

  width: 100%;
  max-height: 200px;
  padding: 4px 0 8px;
  font-family: inherit;
  background: no-repeat bottom;
  background-color: transparent;
  background-image: linear-gradient(
      0deg,
      var(--color-success) 1px,
      rgba(0, 150, 136, 0) 0
    ),
    linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
  background-size: 0 100%, 100% 100%;
  border: none;
  border-radius: 0;
  outline: none;
  transition: background 0s ease-out;
  resize: none;
}

.textArea:focus {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
}

.textArea::placeholder {
  color: get-color('text', 'secondary');
}

.hasError {
  background-image: linear-gradient(
      0deg,
      var(--color-warning) 1px,
      rgba(0, 150, 136, 0) 0
    ),
    linear-gradient(0deg, #e8ecec 1px, transparent 0) !important;
  background-size: 100% 100%, 100% 100%;
}
