@import '../../scss/common';
@import '../../scss/number';

.number {
  margin: 0;
  font-weight: map-get($weights, 'black');
}

.numberLevel1 {
  @extend %numberLevel1;
}

.numberLevel2 {
  @extend %numberLevel2;
}

.numberLevel3 {
  @extend %numberLevel3;
}

.numberLevel4 {
  @extend %numberLevel4;
}

.numberLevel5 {
  @extend %numberLevel5;
}

.numberLevel6 {
  @extend %numberLevel6;
}

.numberLevel7 {
  @extend %numberLevel7;
}
