%numberLevel1 {
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 2px;
}

%numberLevel2 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 2px;
}

%numberLevel3 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1.4px;
}

%numberLevel4 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.4px;
}

%numberLevel5 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.4px;
}

%numberLevel6 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.8px;
}

%numberLevel7 {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.6px;
}
