.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
}

.menuItem {
  cursor: pointer;
}

.menuLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  padding: 20px 30px;
  color: inherit;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  opacity: 0.7;
  transition: opacity 0.2ms ease, background-color 0.2s ease;
}

.menuLink:focus {
  outline: none;
}

.menuLink:hover,
.menuLinkActive {
  color: inherit;
  background-color: rgba(9, 212, 141, 0.1);
  opacity: 1;
}

.menuLinkActive::after {
  position: absolute;
  right: 0;
  display: block;
  width: 5px;
  height: 100%;
  background-color: var(--color-success);
  border-radius: 2px;
  content: '';
}

.simple .menuLink {
  padding: 20px;
}

.simple .menuLink:hover,
.simple .menuLinkActive {
  background-color: #f8f9fa;
  border-radius: 10px;
}

.simple .menuLinkActive::after {
  display: none;
}
