.group {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.title {
  letter-spacing: 1.7px;
  text-transform: uppercase;
}

.content {
  padding-top: 16px;
}
