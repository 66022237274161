@value borderRadius: 8px;

.table {
  position: relative;
  width: 100%;
  background-color: #fff;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}

.table tfoot tr:hover td {
  background-color: inherit !important;
}
