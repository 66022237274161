@import 'variables';

@function format-media-query-string($queries) {
  $query: '';

  @each $item in $queries {
    $query: $query + ' and (' + $item + ')';
  }

  @return $query;
}

@mixin mobile($queries...) {
  @media #{'(max-width: #{map-get($breakpoints, 'xl') - 1px})' + format-media-query-string($queries)} {
    @content;
  }
}

@mixin desktop($queries...) {
  @media #{'(min-width: #{map-get($breakpoints, 'xl')})'  + format-media-query-string($queries)} {
    @content;
  }
}

@mixin accessibilityHidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
