@font-face {
  font-weight: normal;
  font-family: 'masked-password';
  font-style: normal;
  src: url('assets/masked-password.woff2') format('woff2'),
    url('assets/masked-password.woff') format('woff');
}

.default {
  position: relative;
}

.input {
  padding-right: 40px;
}

.maskedPassword {
  font-family: 'masked-password', sans-serif;
}

.maskedPassword::placeholder {
  font-family: 'NunitoSans', sans-serif;
}

.showButton {
  position: absolute;
  top: 28px;
  right: 0;
  margin: 0;
  padding: 0;
  color: var(--color-warning);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.6px;
  background: none;
  border: none;
  cursor: pointer;
}

.eyeIcon {
  opacity: 0.5;
  transition: opacity ease 0.5s;
}

.eyeIcon:hover {
  opacity: 1;
}

.showButton:focus,
.showButton:active {
  outline: none;
}
