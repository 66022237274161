@import '../../scss/common';
@import '../../scss/text';

.text {
  margin: 0;
}

.textLevel1 {
  @extend %textLevel1;
}

.textLevel2 {
  @extend %textLevel2;
}

.textLevel3 {
  @extend %textLevel3;
}

.textLevel4 {
  @extend %textLevel4;
}

.textLevel5 {
  @extend %textLevel5;
}
