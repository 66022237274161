@import '~@appclose/ui/src/scss/mixins';

@value controlWidth: 50px;
@value controlHeight: 30px;
@value controlSwitchSize: 27px;

.readOnly {
  cursor: unset;
  pointer-events: none;
}

.disabled {
  opacity: 0.7;
}

.checkbox {
  @include accessibilityHidden();
}

.label {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  cursor: pointer;
}

.control {
  position: relative;
  flex-shrink: 0;
  width: controlWidth;
  height: 31px;
  margin-right: 10px;
  cursor: pointer;
}

.disabled .label,
.disabled .control {
  cursor: not-allowed;
}

.controlLabel {
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.controlInner {
  width: 200%;
  height: 100%;
  margin-left: -100%;
  transition: margin-left 0.15s ease-in-out;
}

.controlInner::before,
.controlInner::after {
  display: block;
  float: left;
  width: 50%;
  height: 100%;
  content: '';
}

.controlInner::before {
  background-color: var(--color-success);
}

.controlInner::after {
  background-color: #f1f1f1;
}

.controlSwitch {
  position: absolute;
  top: 2px;
  left: 2px;
  width: controlSwitchSize;
  height: controlSwitchSize;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 3px 3px 0 rgba(0, 0, 0, 0.05);
  transition: left 0.15s ease-in-out;
}

.checkbox:checked ~ .label > .control .controlInner {
  margin-left: 0;
}

.checkbox:checked ~ .label > .control .controlSwitch {
  left: 21px;
}
