.tab {
  padding: 5px;
  color: #989fa3;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  word-break: keep-all;
  cursor: pointer;
}

.selectedTab {
  color: var(--color-default);
  border-bottom: 2px solid var(--color-primary);
}

.disabledTab {
  cursor: not-allowed;
  opacity: 0.5;
}

.tab:not(:first-child) {
  margin-left: 40px;
}
